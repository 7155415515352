import STATUS_CODES from 'constants/statusCodes';
import OpenlaneError from 'partials/error/openlane';

const PageNotFound = () => {
	const title = 'Page not found';
	const description =
		'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.';

	return <OpenlaneError statusCode={STATUS_CODES.NOT_FOUND} title={title} description={description} />;
};

export default PageNotFound;
